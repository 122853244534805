import React  from "react"

// components
import Template from "../../components/Template.js"

const dressing = () => {
  return (
    <div>
      <Template index={2} ></Template>
    </div>
  )
}

export default dressing